const RouteName = {
    HOME: '/',
    SHOP: '/shop',
    LOGIN: '/login',
    SIGNUP: '/signup',
    CART: '/cart',
    CHECKOUT: '/checkout',
    PRODUCT_DETAILS: '/product',
    TNC: '/tnc',
    PRIVACY_POLICY: '/privacy-policy',
    ERROR: '/error',
    ACCOUNT: '/account',
    GALLERY: '/gallery',
    RETURN_POLICY: '/return-policy',
    RESET_PASSWORD: '/reset-password',
    ABOUTUS: '/about-us',
    CONTACT: '/contact',
    FAQ: '/faq',
    DISCOUNT: '/discount',
    SHOP_ACCESSORIES: '/shop-accessories',
};

export default RouteName;