const productActionTypes = {
    SET_PRODUCTS_DATA: 'SET_PRODUCTS_DATA',
    SET_PAGINATION_NUMBER: 'SET_PAGINATION_NUMBER',
    SET_ACCESSORIES_PAGINATION_NUMBER: 'SET_ACCESSORIES_PAGINATION_NUMBER',
    SET_PRODUCT_LIST: 'SET_PRODUCT_LIST',
    SET_PRODUCT_TYPE: 'SET_PRODUCT_TYPE',
    SET_GENRE_TYPE: 'SET_GENRE_TYPE',
    SET_PRODUCT_CATEGORIES: 'SET_PRODUCT_CATEGORIES',
    SET_GENRE_CATEGORIES: 'SET_GENRE_CATEGORIES',
    SET_PRODUCT_DETAILS: 'SET_PRODUCT_DETAILS',
    ADD_TO_CART: 'ADD_TO_CART',
    UPDATE_CART: 'UPDATE_CART',
    SET_STOCK_AVAILABILITY: 'SET_STOCK_AVAILABILITY',
    SET_BEST_SELLERS: 'SET_BEST_SELLERS',
    SET_WEEKLY_DROP: 'SET_WEEKLY_DROP',
    SET_ACCESSORIES_DATA: 'SET_ACCESSORIES_DATA',
    SET_ACCESSORIES_LIST: 'SET_ACCESSORIES_LIST'
};

export default productActionTypes;