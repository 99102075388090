const PRODUCT_TYPE = {
    HST: "HST",
    FST: "FST",
    HOODIES: "HOODIES",
    SWTS: "SWTS",
    OST: "OST"
};

export const PRODUCT_NAME = {
    HST: "Regular Tees",
    FST: "Full Sleeve Tees",
    HOODIES: "Hoodies",
    SWTS: "Sweatshirts",
    OST: "Oversize Tees"
}

export default PRODUCT_TYPE;