const CSSConstants = {
    BACKGROUND_PRIMARY: "#171717",
    BACKGROUND_SECONDARY: "#161616",
    BACKGROUND_HIGHLIGHT: "#1C1C1C",
    FONT_PRIMARY: "#F2F2F2",
    FONT_SECONDARY: "#695FE4",
    FONT_BLACK: '#000000',
    FONT_HIGHLIGHT: '#8fcaf9',
    ERROR: '#f44336',
    LANDING_PAGE_HIGHLIGHTER: "#6dcff6",
    HIGHLIGHTER: "#FF3156"
};

export default CSSConstants;